import User from 'services/user';
import { useEffect, useMemo, useState } from 'react';
import { columnsArrayEmployees } from './employeesTableData';
import { Client, CLIENT_ROLE, UserOnClient } from 'types/user';
import { useUserStore } from 'contexts/UserContext';
import { ShowNotification } from 'tools/showNotification';
import { useTranslation } from 'react-i18next';

export const useEmployees = () => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const client = userStore.client as Client;
  const user = userStore.user;

  const columns = useMemo(() => columnsArrayEmployees(), []);
  const [clients, setClients] = useState<UserOnClient[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUsers = async () => {
    setIsLoading(true);

    try {
      const result = await User.getClientForId(client?.id);

      const sortedData = result?.data?.users?.sort((a: any, b: any) => {
        const aIsUndefined = a?.id === undefined;
        if (aIsUndefined) a.tempId = result?.data?.users.length;
        const bIsUndefined = b?.id === undefined;
        if (bIsUndefined) b.tempId = result?.data?.users.length + 1;
        const aUndefinedUserSerialNumber = a?.serialNumber?.id;
        const bUndefinedUserSerialNumber = b?.serialNumber?.id;

        if (a.tempId && b.tempId) return a.tempId - b.tempId;

        if (user?.id === a?.id) return 1;
        if (user?.id === b?.id) return -1;

        if (aIsUndefined && !bIsUndefined) return -1;
        if (!aIsUndefined && bIsUndefined) return 1;

        if (aUndefinedUserSerialNumber && !bUndefinedUserSerialNumber)
          return -1;
        if (!aUndefinedUserSerialNumber && bUndefinedUserSerialNumber) return 1;

        return b.id - a.id;
      });

      setClients(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleDeletePermissions = async (id: number, email: string) => {
    try {
      const result = await User.deleteUserPermissions(client.id, email, id);

      if (result.data.status === true) {
        ShowNotification({
          type: 'success',
          title: t('The employee has been removed'),
          children: t("The user no longer has access to your Client's data"),
          isNewToast: true,
        });
        setClients(
          (prev) => prev?.filter((item) => item.email !== email) || []
        );
      } else {
        ShowNotification({
          type: 'error',
          children: t('Error deleting user'),
        });
      }
    } catch (error) {
      console.error('Error deleting user permissions:', error);
    }
  };

  const handleChangePermissions = async (
    id: number,
    role: CLIENT_ROLE,
    email: string
  ) => {
    try {
      const result = await User.changeUserPermissions(
        client.id,
        role,
        email,
        id
      );

      if (result.data.status === true) {
        setClients(
          (prev) =>
            prev?.map((item) =>
              item.email === email
                ? { ...item, permission: { ...item.permission, type: role } }
                : item
            ) || []
        );
      }
    } catch (error) {
      console.error('Error deleting user permissions:', error);
    }
  };

  return {
    client,
    isLoading,
    clients,
    columns,
    handleDeletePermissions,
    getUsers,
    handleChangePermissions,
  };
};
