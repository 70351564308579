import { Label } from './Label';
import { Icon } from 'components/shared/Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import { formatNumberWithSpace } from './formatNumberWithSpace';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { QuestionIcon } from 'components/shared/Icons/QuestionIcon';
import { InputProps } from './Input.props';
import { RUSSIAN_LOCALE } from 'utils';
import UiIcon from '../Icon';
import { forwardRef } from 'react';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      labelPosition,
      type = 'text',
      placeholder,
      disabled = false,
      readOnly = false,
      error,
      value,
      icon,
      iconJSX,
      iconFill,
      iconHeight,
      iconWidth,
      className,
      onChange,
      onBlur,
      onFocus,
      maxLength,
      onKeyDown,
      currency,
      inputForCurrency = false,
      inputLabel,
      clearIcon,
      helperText,
      onPaste,
      bigInput,
      isBackground,
      onMouseEnter,
      onMouseLeave,
      errorAbsolute,
      wadeTooltip,
      isBigInput = false,
    }: InputProps,
    ref
  ) => {
    const isMexicanPesos =
      currency && (currency === 'MXN' || currency === 'MX$');
    const dollarIcon =
      currency === 'USD' ? (
        <UiIcon
          name="USDIcon"
          additionalClassName={style.currencyWrap__dollarIcon}
        />
      ) : isMexicanPesos ? (
        <UiIcon
          name="USDMexicanIcon"
          additionalClassName={style.currencyWrap__dollarIcon}
        />
      ) : (
        currency
      );
    const classNames = clsx(
      style.input,
      isBigInput && style.isBigInput,
      (icon || iconJSX) && style.inputicon,
      currency && style.inputCurrency,
      error && style.inputError,
      inputLabel && style.inputLabel,
      value && value?.length === 0 && inputLabel && style.inputLabelEmptyInput,
      RUSSIAN_LOCALE && !isBackground && style.ru,
      className
    );

    const formattedValue = inputForCurrency
      ? formatNumberWithSpace(value)
      : value;

    return (
      <div className={clsx(style.inputWrap, className)}>
        {label && <Label position={labelPosition}>{label}</Label>}

        <div className={style.inputInner}>
          {icon && !iconJSX && (
            <div className={style.iconWrap}>
              <Icon
                name={icon}
                width={iconWidth}
                height={iconHeight}
                iconFill={iconFill}
              />
            </div>
          )}

          {!icon && iconJSX && <div className={style.iconWrap}>{iconJSX}</div>}

          {currency && <div className={style.currencyWrap}>{dollarIcon}</div>}

          <input
            ref={ref}
            type={type}
            value={formattedValue || ''}
            className={classNames}
            placeholder={inputLabel ? '' : placeholder}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => onChange && onChange(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            maxLength={maxLength || undefined}
            onKeyDown={onKeyDown}
            onPaste={onPaste || undefined}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            autoFocus={false}
          />

          {inputLabel && (
            <div
              className={clsx(
                style.inputLabelPlaceholder,
                (icon || iconJSX) && style.inputIconPlaceholder,
                value?.length > 0 && style.inputLabelPlaceholderActive,
                style.inputLabelPlaceholderDefault,
                bigInput && style.bigInput
              )}
            >
              {placeholder}
            </div>
          )}

          {clearIcon && value?.length > 0 && (
            <CrossIcon
              className={style.clearIcon}
              onClick={() => onChange && onChange('')}
              width={'15px'}
              height={'15px'}
            />
          )}

          {helperText && (
            <TooltipContainer
              text={helperText}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={style.error}
              classNameTooltip={clsx(
                style.helperTextTooltip,
                wadeTooltip && style.wadeTooltip
              )}
            >
              <QuestionIcon className={style.questionIcon} />
            </TooltipContainer>
          )}
        </div>

        {error && typeof error === 'string' && (
          <div
            className={clsx(
              style.errorText,
              errorAbsolute && style.errorAbsolute
            )}
          >
            {error}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
