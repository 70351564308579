import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  registrationRuStepOneSchema,
  registrationRuStepOneWithInviteSchema,
} from 'containers/Account/validationSchemas';
import Service from 'services/service';
import { getLangName } from 'tools/utils';
import { ShowNotification } from 'tools/showNotification';
import { RegistrationRuStepOneProps } from 'types/auth';
import {
  BusinessSelectedType,
  BusinessType,
  CompanySizeType,
} from 'types/solutions';
import { ObjectSchema, Schema } from 'yup';
import { getYmClientId } from 'tools/analytics';
import { authStore } from 'stores/authStore';
import { setAccessToken, setRefreshToken } from 'services/http';

export const useRegistrationFormRu = (
  kidUserInfo: { [key: string]: any } | null
) => {
  const isInvite = kidUserInfo && kidUserInfo.invite;

  const { t, i18n } = useTranslation();
  const [schema, setSchema] = useState<Schema>(
    isInvite
      ? registrationRuStepOneWithInviteSchema(t)
      : registrationRuStepOneSchema(t)
  );
  const currentLanguage = localStorage.getItem('kitGlobalLng');
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    setValue,
    watch,
  } = useForm<RegistrationRuStepOneProps>({
    resolver: yupResolver(schema as ObjectSchema<RegistrationRuStepOneProps>),
    mode: 'all',
  });

  useEffect(() => {
    if (kidUserInfo) {
      setValue('kid_token', kidUserInfo.kid_token);
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    setSchema(
      isInvite
        ? registrationRuStepOneWithInviteSchema(t)
        : registrationRuStepOneSchema(t)
    );
  }, [currentLanguage, isInvite]);

  const { companyName, url } = getValues();
  const formRef = useRef(null);
  const [goals, setGoals] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [check, setCheck] = useState(false);
  const [selectedCompanySize, setSelectedCompanySize] =
    useState<CompanySizeType>();
  const [businessTypes, setBusinessTypes] = useState<BusinessSelectedType[]>(
    []
  );
  const [selectedBusinessType, setSelectedBusinessType] =
    useState<BusinessSelectedType>();
  const [checkErrors, setCheckErrors] = useState(false);
  const [isStepOneValid, setIsStepOneValid] = useState(false);

  const setErrorMessage = (
    field: keyof RegistrationRuStepOneProps,
    message: string
  ) => {
    setError(field, { type: 'server', message });
  };

  useEffect(() => {
    const getBusinessTypes = async () => {
      try {
        const response = await Service.getBusinessTypes();

        const data = response?.data?.results?.map((item: BusinessType) => ({
          id: item.id,
          name: getLangName(item, 'name', i18n),
        }));
        setBusinessTypes(data);
      } catch (error) {
        setBusinessTypes([]);
      }
    };
    getBusinessTypes();
  }, []);

  const submit = useCallback(async () => {
    const ymClientId = getYmClientId();

    const formData = new FormData();

    setCheckErrors(isValid);

    if (kidUserInfo) {
      formData.append('kid_token', kidUserInfo.token);
      formData.append(
        'full_name',
        `${kidUserInfo.first_name} ${kidUserInfo.last_name}`
      );
      formData.append('email', kidUserInfo.email);
      formData.append('phone_number', kidUserInfo.phone);
    }

    if (!isInvite) {
      if (selectedCompanySize)
        formData.append('company_size', selectedCompanySize?.id);
      if (selectedBusinessType)
        formData.append('company_industry', String(selectedBusinessType?.id));
      if (companyName) formData.append('company_name', companyName.trim());
      if (url) formData.append('company_link', url.trim());
    }

    formData.append('language', i18n.language);

    if (goals.length > 0) {
      goals.map((goal) => {
        formData.append('goal', goal);
        return null;
      });
    }
    if (comment !== '') {
      formData.append('other_field_value', comment);
    }
    if (check) {
      formData.append('free_consultation', 'on');
    }

    if (ymClientId) {
      formData.append('ym_client_id', ymClientId);
    }

    const source_page = localStorage.getItem('source_page');
    if (source_page) {
      formData.append('source_page', source_page);
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: {
        'Platform-Type': process.env.REACT_APP_PLATFORM_TYPE || '',
      },
    };

    fetch(
      `${process.env.REACT_APP_API}/register/${isInvite ? '?invite=1' : ''}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        const { form_errors, status, message } = response || {};
        const errorMessage = form_errors?.kid_token || message;

        if (form_errors || status === false) {
          ShowNotification({ type: 'error', children: t(errorMessage) });
          const { company_name, company_link } = form_errors || {};

          if (company_name) {
            setErrorMessage('companyName', company_name[0]);
          }
          if (company_link) {
            setErrorMessage('url', company_link[0]);
          }
        } else {
          authStore.setAuth(true);
          setAccessToken(response.data.access);
          setRefreshToken(response.data.refresh);
          authStore.accessToken = response.data.access;

          if (isInvite) {
            navigate('/account/personal', { replace: true });
          } else {
            navigate(process.env.REACT_APP_LOGIN_SUCCESS_URL as string, {
              replace: true,
            });
          }
        }
      });
  }, [
    formRef,
    companyName,
    url,
    goals,
    check,
    comment,
    selectedBusinessType,
    selectedCompanySize,
  ]);

  const [, setcompanyNameSelect] = useState('');
  const [companyLink, setcompanyLink] = useState('');

  const isAgree = watch('iAgree');

  useEffect(() => {
    const isValidInvite = isInvite && isAgree;
    const isValidNonInvite =
      !isInvite &&
      companyName !== '' &&
      companyLink !== '' &&
      companyLink.length <= 50 &&
      isAgree &&
      selectedCompanySize &&
      selectedBusinessType;

    setIsStepOneValid(isValidInvite || isValidNonInvite);
  }, [
    isInvite,
    isValid,
    companyName,
    companyLink,
    isAgree,
    selectedCompanySize,
    selectedBusinessType,
  ]);

  return {
    formRef,
    handleSubmit,
    submit,
    t,
    setcompanyNameSelect,
    selectedBusinessType,
    isStepOneValid,
    businessTypes,
    setSelectedBusinessType,
    i18n,
    setSelectedCompanySize,
    errors,
    control,
    setcompanyLink,
    selectedCompanySize,
    isInvite,
  };
};
