import classes from './Toast.module.scss';
import clsx from 'clsx';
import { CheckCircleIcon } from 'components/shared/Icons/CheckCirleIcon';
import { UnionIcon } from 'components/shared/Icons/UnionIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { ToastProps } from './Toast.props';
import CircleIcon from '../CircleIcon';

export const Toast = ({
  type,
  children,
  toastClass,
  childrenClass,
  toastIconClass,
  title,
  isNewToast,
}: ToastProps) => (
  <div
    className={clsx(
      classes['toast-container'],
      classes[type],
      toastClass,
      isNewToast && classes['toast-container_new']
    )}
  >
    <div className={clsx(classes.toast, classes[type], toastClass)}>
      {type === 'success_second' ? (
        <CircleIcon
          className={classes.icon__circle}
          icon={
            <CheckCircleIcon
              className={classes.iconUI}
              fill={'#86CE53'}
              width={'100%'}
              height={'100%'}
            />
          }
        />
      ) : (
        <div className={clsx(classes.icon, toastIconClass)}>
          {type === 'success' && (
            <CheckCircleIcon
              className={classes.iconUI}
              fill={'#00C853'}
              width={'100%'}
              height={'100%'}
            />
          )}

          {type === 'warning' && (
            <InfoIcon
              className={classes.iconUI}
              fill={'#F3CA37'}
              width={'100%'}
              height={'100%'}
            />
          )}

          {type === 'error' && (
            <UnionIcon
              className={classes.iconUI}
              fill={'#F5222D'}
              width={'100%'}
              height={'100%'}
            />
          )}
        </div>
      )}

      <div className={classes.titleWrapper}>
        {title && (
          <div className={clsx(classes.titleText, classes[type])}>{title}</div>
        )}

        <span className={clsx(classes.text, childrenClass, classes[type])}>
          {children}
        </span>
      </div>
    </div>
  </div>
);
