import {
  usePagination,
  useTable,
  useSortBy,
  TableCellProps,
  ColumnInstance,
} from 'react-table';
import classes from './TableEmployees.module.scss';
import clsx from 'classnames';
import { Fader } from 'components/shared/Fader';
import _ from 'lodash';
import {
  TableCellCustomProps,
  TableEmployeesProps,
} from './TableEmployees.props';
import { useTranslation } from 'react-i18next';
import { CLIENT_ROLE } from 'types/user';
import RoleCell from './RoleCell/RoleCell';
import MenuCell from './MenuCell/MenuCell';
import { Pagination } from 'components/shared/Pagination';
import { useMemo, useState } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { userStore } from 'stores/userStore';

const TableEmployees = ({
  data,
  columns,
  additionalClassNames,
  id,
  client,
  handleDeletePermissions,
  handleChangePermissions,
}: TableEmployeesProps) => {
  const { t } = useTranslation();
  const { width } = useWindowWidth();
  const user = userStore.user;

  const [search, setSearch] = useState<string>('');
  const filteredData = useMemo(() => {
    if (!search) return data;
    return data.filter((row: any) =>
      row.user.value.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data: filteredData, initialState: { pageSize: 8 } },
    useSortBy,
    usePagination
  );

  const renderFilterByHeader = (header: string) => {
    switch (header) {
      default:
        return null;
    }
  };

  const mainContent = (value: TableCellProps & TableCellCustomProps) =>
    value?.value;

  return (
    <Fader active={false}>
      <div
        className={clsx(
          classes.table,
          additionalClassNames && String(additionalClassNames)
        )}
        id={id}
      >
        <table {...getTableProps()} className={classes.table__wrapper}>
          <thead>
            {headerGroups?.map((headerGroup) => {
              const headerProps = headerGroup.getHeaderGroupProps();
              const { key: headerKey, ...restHeaderProps } = headerProps;

              return (
                <tr
                  key={headerKey}
                  {...restHeaderProps}
                  className={classes.table__row}
                >
                  <th className={classes.table__cell}>
                    {/* <FilterSearch
                      classNameForButton={classes.table__search}
                      buttonElement={<SearchIcon />}
                      usePortal
                    >
                      {() => (
                        <>
                          <p className={classes.search__description}>
                            Search by table
                          </p>
                          <Input
                            className={classes.search__input}
                            onChange={setSearch}
                            type={'text'}
                            placeholder={t('Enter value...')}
                            value={search}
                            inputForCurrency={true}
                          />
                        </>
                      )}
                    </FilterSearch> */}
                  </th>

                  {headerGroup?.headers?.map((column: ColumnInstance<{}>) => {
                    if (column.render('header')) {
                      const columnProps = column.getHeaderProps();
                      const { key: columnKey, ...restColumnPropsProps } =
                        columnProps;

                      return (
                        <th
                          key={columnKey}
                          className={classes.table__cell}
                          {...restColumnPropsProps}
                        >
                          {t(column.render('header') as string)}

                          {renderFilterByHeader(
                            column.render('header') as string
                          )}
                        </th>
                      );
                    }

                    return null;
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              const { key: rowKey, ...restRowProps } = rowProps;

              return (
                <tr
                  key={rowKey}
                  {...restRowProps}
                  className={classes.table__content}
                >
                  {row?.cells?.map((cell) => {
                    const cellProps = cell.getCellProps();
                    const { key: cellKey, ...restCellProps } = cellProps;
                    const { value } = cell;
                    const isClient = value.id === user.id;
                    const isNotAdmin =
                      client?.permission?.type !== CLIENT_ROLE.ADMIN;
                    const isDisabledChangeRole = isNotAdmin || isClient;
                    const isShowMenu =
                      value?.isMenu && !isNotAdmin && !isClient;

                    return (
                      <td
                        key={cellKey}
                        {...restCellProps}
                        className={classes.table__cell}
                      >
                        <div className={classes.table__cell__container}>
                          <div className={classes.wrapperHeader}>
                            <div
                              className={classes.table__header}
                              style={
                                value.id
                                  ? { maxWidth: width - 70 }
                                  : { opacity: 0.4, maxWidth: width - 75 }
                              }
                            >
                              {mainContent(value)}{' '}
                            </div>
                            {value.isName && isClient && (
                              <span className={classes.you}>({t('you')})</span>
                            )}
                          </div>

                          {value?.label && (
                            <RoleCell
                              key={value.id}
                              value={value}
                              isDisabledChangeRole={isDisabledChangeRole}
                              handleChangePermissions={handleChangePermissions}
                            />
                          )}

                          {isShowMenu && (
                            <MenuCell
                              key={value.id}
                              value={value}
                              isDisabledChangeRole={isDisabledChangeRole}
                              handleDeletePermissions={handleDeletePermissions}
                              handleChangePermissions={handleChangePermissions}
                            />
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {pageCount > 1 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={pageIndex + 1}
              totalPages={pageCount}
              setPageNumber={(page) => gotoPage(page - 1)}
              nextPage={canNextPage ? nextPage : undefined}
              prevPage={canPreviousPage ? previousPage : undefined}
            />
          </div>
        )}
      </div>
    </Fader>
  );
};

export default TableEmployees;
